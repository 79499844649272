<template>
  <legend class="question-title d-flex flex-row align-items-center flex-nowrap">
    <LanguageSelector :to-be-selected="questionTitle" />
    <span v-if="isRequired">*</span>
    <span
      v-if="showCurrentNumberOfQuestion"
      class="question-number p-0 ms-1 d-flex stay-ltr"
    >
      <span>(</span>
      <span>{{ currentNumber }}</span>
      <span>/</span>
      <span>{{ totalNumber }}</span>
      <span>)</span>
    </span>
  </legend>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LanguageSelector from './LanguageSelector.vue';

export default defineComponent({
  components: {
    LanguageSelector
  },
  props: {
    questionTitle: {
      type: Object,
      required: true,
    },
    isRequired: {
      type: Boolean,
      required: true,
    },
    currentNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    totalNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
  },

  computed: {
    showCurrentNumberOfQuestion() {
      return this.currentNumber && this.totalNumber ? true : false;
    },
  },
});
</script>
