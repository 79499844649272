<template>
  <div class="progress-bar">
    <div
      :style="[
        { width: currentPercentage },
        progressAnimation,
        progressAnimationDelay,
        progressAnimationDelay,
      ]"
      class="progression"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    currentPercentage: {
      type: String,
      required: true,
    },
    progressAnimation: {
      type: Object,
      required: true,
    },
    progressAnimationDelay: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style scoped>
.progress-bar {
  height: 6px;
  position: relative;
  width: 410px;
  background-color: var(--bg-color-app);
  margin: auto;
  border-radius: 20px;
}

.progression {
  background-color: var(--bs-success);
  height: 6px;
  border-radius: 20px;
}
</style>
