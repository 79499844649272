<template>
  <div
    :class="{ expanded: expanded, tease: tease }"
    class="landscape-dashboard-container"
  >
    <div class="research-mobile-dashboard d-flex">
      <button
        v-if="showToggleButton"
        class="research-dashboard-button"
        @click="toggleExpanded"
      >
        <font-awesome-icon
          :icon="expanded || tease ? 'chevron-right' : 'chevron-left'"
        />
      </button>
      <DrawerContentPointsMode
        v-if="isPointsMode"
        :dashboard-drawer-label="dashboardDrawerLabel"
        :vertical="true"
      />
      <div
        v-else
        :style="{ color: drawerTitleColor }"
        class="research-dashboard-drawer landscape-text-container px-3 d-flex"
      >
        <div class="drawer-title">
          <LanguageSelector
            :to-be-selected="dashboardDrawerLabel"
            class="mt-1"
          />
          <span
            v-show="spinner"
            style="width: 32px; height: 32px;"
            class="mt-2"
          >
            <font-awesome-icon
              icon="sync"
              class="fa-spin"
              style="color: #004876; margin-top:6px;"
            />
          </span>
          <span
            v-show="showRestrictionPreview && !spinner"
            class="mt-2"
          >
            <font-awesome-layers
              style="width: 32px; height: 32px; transform: rotate(90deg);"
            >
              <img
                src="@/assets/img/icon-speedometer.svg"
                style="width: 32px; height: 32px;"
              >
              <font-awesome-icon
                v-if="restrictionPreviewIcon"
                :icon="restrictionPreviewIcon"
                style="margin-left:18px; margin-bottom: 14px; background-color: white;"
                :style="{
                  color: restrictionPreviewIconColor,
                }"
              />
            </font-awesome-layers>
          </span>
        </div>
      </div>
      <div class="landscape-dashboard-attributes-container">
        <ResearchDashboard />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ResearchDashboard from './ResearchDashboard.vue';
import DrawerContentPointsMode from './DrawerContentPointsMode.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ResearchDashboard,
    DrawerContentPointsMode,
  },
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    tease: {
      type: Boolean,
      required: true,
    },
    drawerTitleColor: {
      type: String,
      required: true,
      default: '',
    },
    dashboardDrawerLabel: {
      type: String,
      required: true,
      default: '',
    },
    showRestrictionPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    restrictionPreviewIcon: {
      type: String,
      required: false,
      default: undefined,
    },
    restrictionPreviewIconColor: {
      type: String,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    showToggleButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['toggleExpanded'],
  computed: {
    ...mapState(useConsultationStore, [
      'isPointsMode',
    ]),
  },
  methods: {
    toggleExpanded() {
      this.$emit('toggleExpanded', null);
    },
  },
});
</script>

<style scoped>
.landscape-dashboard-container {
  position: fixed;
  background-color: #ffffff;
  z-index: 10;
  bottom: 0;
  top: 0;
  transition: all 1.1s ease-in-out;
}

html[dir='ltr'] .landscape-dashboard-container {
  right: -340px;
  box-shadow: -3px 0px 6px rgb(0 0 0 / 16%);
}

html[dir='ltr'] .landscape-dashboard-container.tease {
  right: -50px;
}

html[dir='ltr'] .landscape-dashboard-container.expanded {
  right: 0;
}

html[dir='rtl'] .landscape-dashboard-container {
  left: -340px;
  box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
}

html[dir='rtl'] .landscape-dashboard-container.tease {
  left: -50px;
}

html[dir='rtl'] .landscape-dashboard-container.expanded {
  left: 0;
}

.research-mobile-dashboard {
  text-align: center;
  height: 100%;
}

.research-dashboard-button {
  background-color: #ffffff;
  box-shadow: 0px -3px 6px rgb(0 0 0 / 16%);
  position: absolute;
  top: calc(50% - 20px);
  margin-left: auto;
  margin-right: auto;
  outline: none;
  border: none;
  padding: 8px 6px;
  border-radius: 12px;
  color: var(--bs-info);
}

html[dir='ltr'] .research-dashboard-button {
  left: 0;
  transform: translateX(-50%);
}

html[dir='rtl'] .research-dashboard-button {
  right: -12px;
  transform: rotate(180deg);
}

.landscape-text-container {
  display: flex;
}

.research-dashboard-drawer {
  min-height: 44px;
  text-align: center;
  font-weight: bold;
  color: var(--text-color-heading);
  display: flex;
  height: 100%;
}

.drawer-title {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  transition: color 1s ease-out;
}

html[dir='rtl'] .drawer-title {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.exact-target-property-wrapper {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  transition: color 1s ease-out;
}

.landscape-dashboard-attributes-container {
  overflow-y: scroll;
  padding-bottom: 70px;
}
</style>

<style>
.exact-target-property-wrapper span.avc-soft-property-value-wrapper {
  height: auto;
}
</style>
