<template>
  <div
    class="page-container"
  >
    <div class="card page">
      <section
        v-if="!getMobileView"
        class="navigation-wrapper row mb-4 ms-0 me-0"
      >
        <AppNavigation :navigation-panel-top="true" />
      </section>
      <div>
        <div class="page-title">
          <h1 v-show="title">
            <LanguageSelector :to-be-selected="title" />
          </h1>
        </div>
        <div class="page-content">
          <RenderString
            v-if="content"
            :string="content"
          />
        </div>
        <div class="page-video mt-5 mb-5">
          <VimeoPlayer
            v-if="isVimeoVideoAvailable"
            ref="player"
            :video-url="currentVideoUrl"
            :video-id="vimeoVideoId"
            :options="{
              dnt: true,
              responsive: true,
              byline: false,
              title: false,
              controls: true,
              portrait: false,
            }"
            class="instruction-video-vimeo-player mx-auto"
            :class="{ 'w-100': useFullWidthForVideo }"
          />
        </div>
      </div>
      <div class="page-navigation">
        <AppNavigation />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppNavigation from './AppNavigation.vue';
import RenderString from './RenderString.vue';
import { mapState, mapWritableState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    AppNavigation,
    RenderString,
  },
  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'locale',
    ]),

    title() {
      return this.stage.fields.title;
    },

    stageId() {
      return this.stage.stageId;
    },

    content() {
      return this.stage.fields.content && this.stage.fields.content[this.locale];
    },

    videoDesktopUrl() {
      const { video } = this.stage.fields;

      if (!video || !video[this.locale]) {
        return undefined;
      }

      return video[this.locale];
    },

    videoMobileUrl() {
      const { videoMobile } = this.stage.fields;

      if (!videoMobile || !videoMobile[this.locale]) {
        return undefined;
      }

      return videoMobile[this.locale];
    },

    showMobileVideo() {
      return this.videoMobileUrl && this.getMobileView ? true : false;
    },

    isVimeoVideoAvailable() {
      return this.videoDesktopUrl || this.showMobileVideo ? true : false;
    },

    currentVideoUrl() {
      return this.showMobileVideo ? this.videoMobileUrl : this.videoDesktopUrl;
    },

    useFullWidthForVideo() {
      return !this.showMobileVideo;
    },

    vimeoVideoId() {
      const url = this.currentVideoUrl;

      if (!url) {
        return;
      }
      return url.split('/')[3];
    },
  },

  created() {
    this.nextDisabled = false;
  },
});
</script>

<style scoped>
.instruction-video-vimeo-player {
  width: 70%;
}
</style>
