<template>
  <div>
    <label class="container-checkbox">
      <input
        :checked="checked"
        class="page-consent-checkbox"
        type="checkbox"
        @change="$emit('change', $event.target.checked)"
      >
      <span class="checkmark me-2" />
      <slot />
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: Boolean,
  },
  emits: ['change'],
});
</script>

<style scoped>
/* The container */
.container-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  box-sizing: content-box;
  left: 0;
  height: 22px;
  width: 22px;
  margin-right: 10px;
  background-color: white;
  border: solid #e9eff4;
  border-radius: 6px;

  display: flex;
  align-items: centerl;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  box-sizing: content-box;
  content: '';
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
  margin-bottom: 4px;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  width: 6px;
  height: 14px;
  border: solid #fd1f9b;
  border-radius: 2px;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
</style>
