<template>
  <div class="page-container">
    <div class="card page">
      <section
        v-if="!getMobileView"
        class="navigation-wrapper row mb-4 ms-0 me-0"
      >
        <AppNavigation :navigation-panel-top="true" />
      </section>
      <div class="page-title">
        <h1>
          <LanguageSelector
            v-if="title"
            :to-be-selected="title"
          />
        </h1>
      </div>
      <div class="page-content">
        <LanguageSelector
          v-if="content"
          :to-be-selected="content"
        />
      </div>
      <div class="page-consent-wrapper">
        <InputTypeRadioTable
          ref="options"
          :current-question-data="consentOptions"
          :show-user-input-is-required="showUserInputIsRequired"
          :current-question-number="0"
          :current-question-display-number="1"
          :current-user-data="currentUserData"
          :validation-label="$t('pagination.consent_validation_no_selection_message')"
          @send-current-user-data="updateData"
        />
        <div
          v-if="isCookie"
          class="checkcookie p-2 question-border"
          style="border-color: red"
        >
          {{ $t('pagination.cookies_cookie_detected_message') }}
        </div>
      </div>
      <div class="page-navigation">
        <AppNavigation :submit-function="submit" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import { defineComponent } from 'vue';
import InputTypeRadioTable from './InputTypeRadioTable.vue';
import AppNavigation from './AppNavigation.vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { mapState, mapWritableState } from 'pinia';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    InputTypeRadioTable,
    AppNavigation,
  },

  setup() {
    const analyticsStore = useAnalyticsStore();
    const consultationStore = useConsultationStore();
    return {
      analyticsStore,
      isInstanceActive: consultationStore.isInstanceActive,
      submitConsentAnswerData: consultationStore.submitConsentAnswerData,
      setAnswerData: consultationStore.setAnswerData,
    };
  },

  data() {
    return {
      isCookie: false,
      showUserInputIsRequired: false,
    };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
    ]),

    ...mapState(useConsultationStore, [
      'locale',
      'stage',
      'stageId',
      'consentGiven',
      'getAnswerData',
      'getBlockId',
      'getSequenceNumber',
    ]),

    title() {
      return this.stage.fields.title;
    },

    content() {
      return this.stage.fields.content;
    },

    currentUserData() {
      if (this.getAnswerData[this.stageId] === undefined) {
        return {};
      }

      return this.getAnswerData[this.stageId].answers;
    },

    consentOptions() {
      const locale = this.locale;
      const answerOptions = [
        {
          id: 'accept',
          title: { [locale]: this.$t('pagination.accept_terms') },
        },
        {
          id: 'decline',
          title: { [locale]: this.$t('pagination.decline_terms') },
        },
      ];

      const options = {
        id: 'consent',
        answer_options: answerOptions,
        required: true,
        field_type: 'radio_table',
      };

      return options;
    },
  },

  created() {
    this.checkIsCookie();
    this.nextDisabled = true;
  },

  methods: {
    submit() {
      if (this.currentUserData.user_has_answered === false) {
        this.showUserInputIsRequired = true;
        return;
      }

      this.submitConsentAnswerData(this.stageId);
      this.analyticsStore.trackUserAction([`Submit consent: ${this.consentGiven}`, this.getBlockId, this.getSequenceNumber]);
    },


    async getCookie() {
      if (!this.isInstanceActive) {
        return false;
      }

      const slug = this.getInstanceSlug;
      const value = await Cookies.get(`wevaluate.${slug}`);
      return value;
    },

    async checkIsCookie() {
      const cookie = await this.getCookie();
      if (!cookie) {
        this.isCookie = false;
        return;
      }

      this.isCookie = true;
      this.analyticsStore.trackSystemAction(['Show warning', 'Repeated submission']);
    },

    updateData(data) {
      this.nextDisabled = data.user_has_answered === false;

      if (data.answer_id !== null) {
        this.showUserInputIsRequired = data.user_has_answered === false;
      }

      const payload = {
        stageId: this.stageId,
        data: data
      };

      this.setAnswerData(payload);
    },
  },
});
</script>
