<template>
  <input
    :ref="`slider-enabled-${sliderId}`"
    v-model="sliderValue"
    :max="maxValue"
    type="range"
    :min="minValue"
    :disabled="staticMode"
    class="row-fluid ms-auto me-auto mt-1 w-100 styled-slider px-0"
    :class="{ 'slider-progress': !symmetrical }"
    @pointerdown="pointerDownFunction"
    @pointerup="pointerUpFunction"
    @touchend="touchEndFunction"
    @input="updateSliderValue"
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    currentValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    staticMode: {
      type: Boolean,
      default: false,
    },
    sliderId: {
      type: Number,
      required: true,
    },
    symmetrical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    'pointerDownEvent',
    'pointerUpEvent',
    'touchEndEvent',
    'sendSliderValue',
    'sliderLoaded',
  ],

  data() {
    return {
      sliderValue: 0,
      element: null,
      minValue: 0,
    };
  },

  watch: {
    currentValue(value) {
      this.sliderValue = value;
      this.updateSliderTrack(value);
    },
  },

  created() {
    this.sliderValue = this.currentValue;
  },

  mounted() {
    this.element = this.$refs[`slider-enabled-${this.sliderId}`];
    this.element.style.setProperty(
      '--min',
      this.element.min == '' ? '0' : this.element.min
    );
    this.element.style.setProperty(
      '--max',
      this.element.max == '' ? '100' : this.element.max
    );
    this.element.style.setProperty('--value', this.element.value);
    this.element.addEventListener('input', () =>
      this.element.style.setProperty('--value', this.element.value)
    );
    this.$emit('sliderLoaded');
  },

  methods: {
    pointerDownFunction() {
      this.$emit('pointerDownEvent');
    },

    pointerUpFunction() {
      this.$emit('pointerUpEvent', this.sliderValue);
    },

    touchEndFunction() {
      this.$emit('touchEndEvent', this.sliderValue);
    },

    updateSliderValue() {
      this.$emit('sendSliderValue', this.sliderValue);
    },

    updateSliderTrack(value) {
      if (this.element) {
        this.element.style.setProperty('--value', value);
      }
    },
  },
});
</script>
