<template>
  <div class="form-group">
    <QuestionTitle
      v-if="questionTitle"
      :question-title="questionTitle"
      :is-required="isRequired"
      :current-number="currentQuestionDisplayNumber"
      :total-number="totalQuestionNumber"
    />

    <div class="radio-answer-options">
      <div
        v-for="answer in answerOptions"
        :key="answer.id"
        :style="borderColor"
        class="radio-answer-option question-border d-flex flex-nowrap align-items-center ps-0"
        @click="userHasChosenAnswer(answer)"
      >
        <div>
          <input
            :name="currentQuestionData.id"
            :value="answer.id"
            :checked="answerData.raw_input_radio_table === answer.id"
            class="radio-answer-pseudo-button"
            type="radio"
          >
          <div
            class="radio-answer-button d-flex justify-content-center align-items-center ms-3"
          >
            <span
              :class="{
                'radio-button-active': answerData.raw_input_radio_table === answer.id,
              }"
              class="radio-answer-button-inner-circle"
            />
          </div>
        </div>
        <label class="answer-option answer-option-radio-table-label h-100 ms-3">
          <LanguageSelector :to-be-selected="answer.title" />
        </label>
      </div>
    </div>
    <div
      :style="textOpacity"
      class="alert-required-message my-2"
    >
      {{ validationLabel }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import QuestionTitle from './QuestionTitle.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    QuestionTitle,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
      default: undefined,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    totalQuestionNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
    validationLabel: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale'
    ]),

    questionTitle() {
      return this.currentQuestionData.title;
    },

    answerOptions() {
      if (!this.currentQuestionData.answer_options) {
        return [];
      }

      const answers = this.currentQuestionData.answer_options.map((answer) => {
        return {
          id: answer.id,
          title: answer.title[this.locale],
        };
      });

      return answers.filter((answer) => {
        return answer.title !== undefined;
      });
    },

    isChecked() {
      return this.checkedArray;
    },

    isRequired() {
      return this.currentQuestionData.required;
    },

    borderColor() {
      return this.showUserInputIsRequired
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    textOpacity() {
      return this.showUserInputIsRequired ? { opacity: '1' } : { opacity: '0' };
    },
  },

  created() {
    const answerData = this.currentUserData ? { ...this.currentUserData } : {};

    if (answerData.user_has_answered === true) {
      this.onDropdownDisplay = answerData.user_answered_string;
    } else {
      answerData.user_has_answered = false;
      answerData.user_answered_string = null;
      answerData.raw_input_radio_table = null;
      answerData.answer_id = null;
    }

    answerData.id = this.currentQuestionData.id;
    answerData.title = this.questionTitle;
    answerData.required = this.isRequired;
    answerData.field_type = this.currentQuestionData.field_type;
    answerData.question_number = this.currentQuestionNumber;
    answerData.answer_options = this.answerOptions;

    this.answerData = answerData;

    this.$emit('sendCurrentUserData', this.answerData);
  },

  methods: {
    userHasChosenAnswer(answer) {
      this.answerData.user_answered_string = answer.title;
      this.answerData.user_has_answered = true;
      this.answerData.raw_input_radio_table = answer.id;
      this.answerData.answer_id = answer.id;

      this.$emit('sendCurrentUserData', this.answerData);
    },
  },
});
</script>

<style scoped>
.radio-answer-options {
  width: 100%;
  height: 100%;
  padding-left: 0;
  margin-left: 0;
}
</style>
