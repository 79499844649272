import { Property } from "../../types/Property";

const GREEN = '#92C301';

const ORANGE = '#EAB400';

const RED = '#D40005';

export function restrictiveSegmentColors(property: Property) {
  const colors = [];

  if (property.restrictive_min_value !== null) {
    colors.push(RED);
  }

  if (property.flexible_min_value !== null) {
    colors.push(ORANGE);
  }

  colors.push(GREEN);

  if (property.flexible_max_value !== null) {
    colors.push(ORANGE);
  }

  if (property.restrictive_max_value !== null) {
    colors.push(RED);
  }

  return colors;
}
