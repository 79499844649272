<template>
  <DashboardDrawerPortrait
    v-if="!isLandscapeMode"
    :expanded="expanded"
    :tease="tease"
    :drawer-title-color="getDrawerTitleColor"
    :spinner="spinner"
    :show-restriction-preview="showRestrictionPreview"
    :restriction-preview-icon="restrictionPreviewIcon"
    :restriction-preview-icon-color="restrictionPreviewIconColor"
    :dashboard-drawer-label="dashboardDrawerLabel"
    :justify-content-class="getJustifyContentClass"
    :show-toggle-button="showToggleButton"
    @toggle-expanded="toggleExpanded()"
  />
  <DashboardDrawerLandscape
    v-else
    :expanded="expanded"
    :tease="tease"
    :drawer-title-color="getDrawerTitleColor"
    :spinner="spinner"
    :show-restriction-preview="showRestrictionPreview"
    :restriction-preview-icon="restrictionPreviewIcon"
    :restriction-preview-icon-color="restrictionPreviewIconColor"
    :dashboard-drawer-label="dashboardDrawerLabel"
    :justify-content-class="getJustifyContentClass"
    :show-toggle-button="showToggleButton"
    @toggle-expanded="toggleExpanded()"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { gsap } from 'gsap';
import DashboardDrawerLandscape from './DashboardDrawerLandscape.vue';
import DashboardDrawerPortrait from './DashboardDrawerPortrait.vue';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    DashboardDrawerLandscape,
    DashboardDrawerPortrait,
  },
  data() {
    return {
      expanded: false,
      tease: false,
      spinner: false,
      tweenedNumber: 0,
    };
  },
  computed: {
    ...mapState(useInterfaceStore, [
      'isLandscapeMode'
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'isSliderMode',
      'isPointsMode',
      'getAnswerData',
      'stageProperties',
      'skipChoiceTask',
    ]),

    normalAttributeData() {
      return this.stageProperties.filter(
        (p) => p.restrictive === false && p.visibility.visible_in_right_bar
      );
    },

    showToggleButton() {
      if (this.isPointsMode && this.normalAttributeData.length === 0 && this.getRestrictiveAttributeData.length === 0) {
        return false;
      }

      return true;
    },

    showRestrictionPreview() {
      if (this.getRestrictiveAttributeData.length === 0 || this.isPointsMode) {
        return false;
      }

      return true
    },

    restrictionPreviewIconColor() {
      if (this.restrictionPreviewIcon === 'ban') {
        return '#D40005';
      } else if (this.restrictionPreviewIcon === 'exclamation-triangle') {
        return '#EAB400';
      } else if (this.restrictionPreviewIcon === 'check-circle') {
        return '#92C301';
      }

      return 'var(--text-color-heading)';
    },

    restrictionPreviewIcon() {
      if (this.skipChoiceTask) {
        return undefined;
      }

      if (this.getRestrictiveAttributeData.length === 0 || this.isPointsMode) {
        return undefined;
      }

      let hasWarnings = false;
      let hasErrors = false;

      this.stageProperties.forEach((attribute) => {
        if (!attribute.restrictive && !this.getExactTargetProperty) {
          return;
        }

        const state = attribute.current_calculated_value_exceeds_restriction;

        if (!state) {
          return;
        }

        if (state === 'min' || state === 'max') {
          hasErrors = true;
        } else if (state === 'flex-min' || state === 'flex-max') {
          hasWarnings = true;
        }
      });

      if (hasErrors) {
        return 'ban';
      } else if (hasWarnings) {
        return 'exclamation-triangle';
      }

      return 'check-circle';
    },

    getExactTargetProperty() {
      if (!this.isSliderMode) {
        return false;
      }

      return this.stageProperties.find(
        ({ id }) => id === this.stage.fields.slideToMaxData.exact_target_property
      );
    },

    getExactTargetValue() {
      if (!this.isSliderMode || !this.getExactTargetProperty) {
        return false;
      }

      return this.stage.fields.slideToMaxData.exact_target_property_value;
    },

    getTargetReached() {
      if (!this.isSliderMode || !this.getExactTargetProperty) {
        return false;
      }

      return this.getExactTargetProperty.current_calculated_value === this.getExactTargetValue
    },

    hasOverreachedTarget() {
      if (!this.isSliderMode || !this.getExactTargetProperty) {
        return false;
      }

      return this.getExactTargetProperty.current_calculated_value > this.getExactTargetValue
    },

    hasNotReachedTarget() {
      if (!this.isSliderMode || !this.getExactTargetProperty) {
        return false;
      }

      return this.getExactTargetProperty.current_calculated_value < this.getExactTargetValue
    },

    selectedOptions() {
      return this.getAnswerData[this.stageId].answers;
    },

    getRestrictiveAttributeData() {
      return this.stageProperties.filter((attribute) => attribute.restrictive);
    },

    dashboardDrawerLabel() {
      if (this.isPointsMode) {
        return this.$t('pagination.choice_task_pointsmode_dashboard_mobile_drawer_title');
      }

      return this.$t('pagination.choice_task_dashboard_mobile_drawer_title');
    },

    getDrawerTitleColor() {
      if (this.skipChoiceTask) {
        return '#B7B7B7';
      }

      if (this.getRestrictiveAttributeData.length === 0 && this.isPointsMode) {
        return this.getTargetReached ? '#92C301' : 'var(--text-color-heading)';
      }

      this.setSpinner();

      if (this.tease) {
        return 'var(--text-color-heading)';
      }

      let hasWarnings = false;
      let hasErrors = false;

      if (!this.stageProperties) {
        return;
      }

      this.stageProperties.forEach((attribute) => {
        if (!attribute.restrictive && !this.getExactTargetProperty) {
          return;
        }

        const state = attribute.current_calculated_value_exceeds_restriction;

        if (!state) {
          return;
        }

        if (
          state === 'min' ||
          state === 'max' ||
          this.hasOverreachedTarget ||
          this.hasNotReachedTarget
        ) {
          hasErrors = true;
        } else if (state === 'flex-min' || state === 'flex-max') {
          hasWarnings = true;
        }
      });

      if (hasErrors) {
        return '#D40005'; // red
      } else if (hasWarnings) {
        return '#EAB400'; // orange
      } else if (Object.keys(this.selectedOptions).length > 0) {
        return '#92C301'; //  green
      } else {
        return 'var(--text-color-heading)';
      }
    },

    currentValueForExactTarget() {
      if (this.getExactTargetProperty === undefined) {
        return 0;
      }

      return this.getExactTargetProperty.current_calculated_value;
    },

    exactTargetValue() {
      return this.getExactTargetValue;
    },

    tweenedNumberRounded() {
      if (this.tweenedNumber === undefined) {
        return false;
      }

      return parseInt(this.tweenedNumber.toFixed(0));
    },

    showExactTargetPropertyValues() {
      return this.getExactTargetProperty;
    },

    getJustifyContentClass() {
      return this.getExactTargetProperty
        ? 'justify-content-around'
        : 'justify-content-center';
    },
  },

  watch: {
    currentValueForExactTarget(newValue) {
      gsap.to(this.$data, {
        duration: 0.5,
        tweenedNumber: newValue,
        ease: 'Circ.easeOUT',
      });
    },
  },

  created() {
    if (this.showToggleButton) {
      this.tease = true;
    }
  },

  mounted() {
    this.tweenedNumber = this.currentValueForExactTarget;

    if (this.showToggleButton === false) {
      this.tease = false;
    } else {
      setTimeout(() => {
        this.tease = false;
      }, 1000);
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },

    async setSpinner() {
      this.spinner = true;
      await new Promise((resolve) => setTimeout(resolve, 500));
      this.spinner = false;
    },
  },
});
</script>
