<template>
  <div class="slider-header-wrapper align-items-top align-items-sm-center row">
    <div class="col p-0">
      <div class="d-flex p-0 m-0 flex-nowrap">
        <div
          v-if="optionImage"
          class="d-flex align-items-center justify-content-start justify-content-sm-start p-0 me-2"
        >
          <img
            :src="optionImage"
            class="project-image-slider-header"
          >
        </div>
        <div
          v-if="optionTitle"
          class="p-0 d-flex justify-content-start d-flex align-items-center"
        >
          <LanguageSelector
            class="project-title text-start"
            :to-be-selected="optionTitle"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showInfo"
      class="order-sm-first d-flex justify-content-end justify-content-sm-start p-0 w-auto ms-2 ms-sm-0 me-0 me-sm-2"
    >
      <OptionInfoButton :option="option" />
    </div>
    <div
      v-if="showAttributes && getAttributeData"
      class="restrictive-attribute-wrapper col-12 col-sm d-flex justify-content-end align-items-center p-0 mt-2 mt-sm-0"
    >
      <div
        v-for="data in getAttributeData"
        :key="data.id"
        class="d-flex align-items-center"
      >
        <font-awesome-icon
          v-if="returnRestrictiveIcon(data)"
          :icon="returnRestrictiveIcon(data)"
          class="restrictive-attribute-icon me-1"
        />
        <AttributeValueConverter
          :current-attribute-data="data"
          :option-id="option.id"
          class="restrictive-attribute-value"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import OptionInfoButton from './OptionInfoButton.vue';

export default defineComponent({
  components: {
    OptionInfoButton,
  },

  props: {
    showAttributes: Boolean,
    showInfo: {
      type: Boolean,
      default: true,
    },
    option: {
      type: Object,
      required: true,
    },
    attributeData: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    optionTitle() {
      return this.option.title;
    },

    optionImage() {
      if (this.option.img === null) {
        return false;
      }

      return this.option.img;
    },

    getAttributeData() {
      if (!this.attributeData) {
        return false;
      }

      return this.attributeData;
    },
  },
  methods: {
    returnRestrictiveIcon(data) {
      if (!data.restrictive_icon) {
        return false;
      }

      return data.restrictive_icon;
    },
  },
});
</script>

<style scoped>
.project-title {
  line-height: 18px;
}

.info-button-slider-mode {
  font-size: 28px;
}

.project-image-slider-header {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
}

.restrictive-attribute-wrapper div:not(:first-child) {
  margin-left: 0.5rem;
}

.restrictive-attribute-value {
  line-height: 18px;
}
</style>
