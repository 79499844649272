<template>
  <div class="page-container">
    <div class="card page">
      <div class="page-title">
        <h1>
          {{ $t('pagination.consent_declined_page_title') }}
        </h1>
      </div>
      <div
        v-if="!isPanelParticipant"
        class="page-content"
      >
        {{ $t('pagination.consent_declined_page_content') }}
      </div>
      <div
        v-else-if="isPanelParticipant"
        class="page-content mt-4 pt-2"
        style="border-top-style: solid; border-width: 1px"
      >
        <span v-if="getPanelRejectLink">
          {{ $t('pagination.panel_declined_page_panel_info') }}
        </span>
      </div>
      <div
        v-if="getPanelRejectLink"
        class="page-navigation"
      >
        <AppNavigation />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppNavigation from './AppNavigation.vue';
import { mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    AppNavigation,
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
      'browserBackConfirm',
    ]),

    ...mapState(useConsultationStore, [
      'isPanelParticipant',
      'getPanelRejectLink',
    ]),
  },

  created() {
    this.browserBackConfirm = false;

    if (this.isPanelParticipant && this.getPanelRejectLink) {
      this.nextDisabled = false;
    }
  },

  mounted() {
    if (this.isPanelParticipant && this.getPanelRejectLink) {
      setTimeout(() => {
        window.open(this.getPanelRejectLink, '_self');
      }, 4000);
    }
  },
});
</script>
