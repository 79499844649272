<template>
  <div class="form-group">
    <QuestionTitle
      :question-title="questionTitle"
      :is-required="isRequired"
      :current-number="currentNumber"
      :total-number="totalNumber"
    />

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import QuestionTitle from './QuestionTitle.vue';

export default defineComponent({
  components: {
    QuestionTitle,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    currentNumber: {
      type: Number,
      required: true,
    },
    totalNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    questionTitle() {
      return this.question.title;
    },

    isRequired() {
      return this.question.required;
    },
  },
});
</script>
