<template>
  <div class="page-container p-0">
    <div class="card page">
      <section
        v-if="!getMobileView"
        class="navigation-wrapper mb-4 ms-0 me-0"
      >
        <AppNavigation :navigation-panel-top="true" />
      </section>
      <div class="research-motivation-wrapper card-page-content-width">
        <h1 class="motivation-header mt-0">
          {{ $t('pagination.motivation') }}
        </h1>
        <p class="motivation-explanation">
          {{ $t('pagination.motivation_explanation') }}
        </p>
        <h3 class="motivation-subheader">
          <LanguageSelector :to-be-selected="subHeader" />
        </h3>
        <div
          v-for="option in optionsToMotivate"
          :key="option.id"
        >
          <ChoiceTaskMotivationQuestion
            :current-question-data="option"
            :last-question="false"
            :current-user-data="currentUserDataMotivation(option.id)"
            class="motivation-item"
            @send-current-user-data="updateData"
          />
        </div>

        <AppNavigation :submit-function="submit" />
      </div>
    </div>

    <OptionDetailModal :show="showOptionDetailModal !== false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskMotivationQuestion from './ChoiceTaskMotivationQuestion.vue';
import OptionDetailModal from './OptionDetailModal.vue';
import AppNavigation from './AppNavigation.vue';
import { mapState, mapWritableState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskMotivationQuestion,
    OptionDetailModal,
    AppNavigation,
  },

  setup() {
    const consultationStore = useConsultationStore();
    return { consultationStore };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapWritableState(useConsultationStore, [
      'showOptionDetailModal',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'getAnswerData',
      'isPointsMode',
    ]),

    selectedOptions() {
      const id = `choice-task-${this.stage.blockId}`;

      if (this.getAnswerData[id] === undefined) {
        return []
      }

      return this.getAnswerData[id].answers;
    },

    subHeader() {
      if (this.isPointsMode) {
        return this.$t('pagination.motivation_not_selected_pointsmode_subtitle');
      } else {
        return this.$t('pagination.motivation_not_selected_pickmode_subtitle');
      }
    },

    optionsToMotivate() {
      const selection = this.selectedOptions;

      const optionsToMotivate = [];

      for (const option of this.stage.fields.options) {
        if (selection[option.id] === undefined || selection[option.id].quantity === 0) {
          optionsToMotivate.push(option);
        }
      }

      return optionsToMotivate;
    },
  },

  methods: {
    submit() {
      this.consultationStore.submitChoiceTaskMotivationAnswerData(this.stageId);
    },

    updateData(data) {
      const answerData = this.getAnswerData[this.stageId].answers;

      answerData[data.id] = data;

      const payload = {
        stageId: this.stageId,
        data: answerData
      };

      this.consultationStore.setAnswerData(payload);
    },

    getUserDataChoiceTaskMotivation() {
      if (this.getAnswerData[this.stageId] === undefined) {
        return {};
      }

      return this.getAnswerData[this.stageId].answers;
    },

    currentUserDataMotivation(optionId) {
      return this.getUserDataChoiceTaskMotivation()[optionId];
    },
  },
});
</script>

<style scoped>
.row {
  margin: 0;
  padding: 0;
}

.col {
  margin: 0;
  padding: 0;
}

.motivation-explanation {
  margin: 1em 0;
}

.motivation-item {
  margin-top: 1em;
  margin-bottom: 2em;
}
</style>
