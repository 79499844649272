<template>
  <div class="logo-wrapper">
    <img
      v-if="getCustomConsultationLogo && getMobileView"
      :src="getCustomConsultationLogo"
      class="logo-mobile"
      alt="Consultation Logo"
    >
    <img
      v-else-if="getCustomConsultationLogo && !getMobileView"
      :src="getCustomConsultationLogo"
      class="logo-desktop"
      alt="Consultation Logo"
    >
    <img
      v-else-if="getMobileView"
      src="@/assets/img/logo-mobile.svg"
      class="logo-mobile"
      alt="Consultation Logo"
    >
    <img
      v-else
      src="@/assets/img/logo-desktop.svg"
      class="logo-desktop"
      alt="Consultation Logo"
    >
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'getCustomConsultationLogo',
    ]),
  },
});
</script>

<style scoped>
.logo-wrapper {
  height: 32px;
}

.logo-desktop {
  width: 152.7px;
  height: 32px;
}

.logo-mobile {
  width: 32px;
  height: 32px;
}
</style>
