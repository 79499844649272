<template>
  <span>
    <span
      id="wiki-link-trigger"
      role="button"
      class="wiki-link"
      @click="openWiki()"
    >
      <font-awesome-icon
        :style="returnRotateIconArray()"
        class="more-detail-expandable-arrow-icon"
        style="font-size: 0.8rem"
        icon="chevron-right"
      />
      <span class="wiki-link-underline ms-1"><LanguageSelector :to-be-selected="wikiWord" /></span>
    </span>
    <transition name="dropdown">
      <span
        v-if="isWikiOpen"
        class="wiki-box p-4 mt-4 mb-4"
      ><LanguageSelector :to-be-selected="wikiContent" /></span>
    </transition>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    wikiData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isWikiOpen: false,
    };
  },

  computed: {
    wikiWord() {
      return this.wikiData.wiki_word;
    },

    wikiContent() {
      return this.wikiData.wiki_content;
    },
  },

  methods: {
    openWiki() {
      if (this.isWikiOpen) {
        this.isWikiOpen = false;
      } else {
        this.isWikiOpen = true;
      }
    },

    returnRotateIconArray() {
      if (this.isWikiOpen) {
        return {
          transform: 'rotate(90deg)',
        };
      }
    },
  },
});
</script>

<style>
.wiki-link {
  color: var(--text-color-heading);
  cursor: pointer;
  user-select: none;
  white-space: break-spaces;
  font-weight: 500;
}

.wiki-link:hover {
  color: var(--text-color-heading);
  filter: brightness(2);
}

.wiki-link-underline span {
  text-decoration: underline;
  white-space: break-spaces;
}

.wiki-box {
  display: block;
  background: #f2f2f2;
}
</style>
