import { BlockConsent } from "../../../types/BlockConsent";
import { StageConsent } from "../../../types/StageConsent";

export function ConsentAdapter(data: BlockConsent): StageConsent[] {
  if (data.stages.length === 0) {
    return [];
  }

  // We only support one stage for consent.
  const stage = data.stages[0];

  const segment: StageConsent = {
    id: `consent-${stage.id}`,
    blockId: data.id,
    stageId: stage.id,
    blockType: 'consent',
    stageType: 'consent',
    segmentType: 'Page',
    component: 'SegmentConsent',
    conditions: data.conditions,
    hidden: false,
    fields: {
      title: stage.title,
      content: stage.content,
    }
  };

  return [segment];
}
