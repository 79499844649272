<template>
  <transition-group
    class="row me-0 mt-2 ms-0 p-0"
    name="flip-list"
    tag="div"
  >
    <ChoiceTaskSliderModeCard
      v-for="option in stageOptions"
      :key="option.id"
      :option="option"
    />
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskSliderModeCard from './ChoiceTaskSliderModeCard.vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    ChoiceTaskSliderModeCard,
  },

  computed: {
    ...mapState(useConsultationStore, [
      'stageOptions',
    ]),
  },
});
</script>

<style>
.flip-list-move {
  transition: transform 0.7s;
}
</style>
