<template>
  <svg
    id="svgWindow"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 200 200"
    width="100%"
    height="100%"
  >
    <g>
      <circle
        :r="circleRadius"
        cx="100"
        cy="100"
        :stroke-dasharray="strokeDensity"
        :stroke-width="circleWidth"
      />
      <path
        id="circle"
        :d="circleProperties"
        :stroke="circleColor"
        :style="{
          strokeDasharray: circleLength,
          strokeDashoffset: circleValue,
        }"
        :stroke-width="pathWidth"
        :opacity="pathOpacity"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    circleRadius: {
      type: Number,
      required: true,
    },
    circleColor: {
      type: String,
      required: true,
    },
    circleProgress: {
      type: Number,
      required: true,
    },
    strokeDensity: {
      type: Number,
      required: true,
    },
    circleWidth: {
      type: Number,
      required: true,
    },
    pathWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      circleObject: null,
    };
  },
  computed: {
    circlePosX() {
      const posX = 200 / 2 + this.circleRadius;
      return posX;
    },

    circlePosY() {
      const posY = 200 / 2;
      return posY;
    },

    circleProperties() {
      const d = [
        'M',
        this.circlePosX,
        this.circlePosY,
        'a',
        this.circleRadius,
        this.circleRadius,
        0,
        1,
        1,
        0,
        -0.00001,
      ].join(' ');

      return d;
    },

    circleLength() {
      if (this.circleObject) {
        return this.circleObject.getTotalLength();
      }
      return 0;
    },

    circleValue() {
      let value = this.circleProgress * this.circleLength;
      value = this.circleLength - value;

      return value;
    },

    pathOpacity() {
      if (this.circleValue === this.circleLength) {
        return 0;
      }
      return 1;
    },
  },

  mounted() {
    this.circleObject = document.querySelector('#circle');
  },
});
</script>

<style scoped>
svg {
  text-align: center;
  stroke: lightgrey;
  fill: none;
  stroke-width: 10;
  height: 100%;
  width: 100%;
}

#circle {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: all 0.4s ease-out, stroke 1s ease-out;
}
</style>
