<template>
  <div
    :class="{
      'header-landscape': landscapeMode && inChoiceTask,
    }"
    class="header-container"
  >
    <header
      id="header"
      :class="getMobileView ? 'header-relative' : 'header-fixed'"
      class="header-wrapper container-fluid row m-0 p-0 align-items-center flex-nowrap"
    >
      <div class="col-2 col-sm-3 col-md-4">
        <div class="row d-flex flex-row justify-content-start align-items-center">
          <AppHeaderLogo class="ps-2 ps-sm-3 ps-md-4 w-auto pe-2 pe-sm-3 pe-md-4" />
          <div
            v-if="!getMobileView && getProjectTitle"
            class="header-project-title p-0 w-100"
          >
            <LanguageSelector :to-be-selected="getProjectTitle" />
          </div>
        </div>
      </div>
      <div class="col-8 col-sm-6 col-md-4 d-flex align-items-center ps-0 pe-0">
        <AppHeaderProgressBar
          v-if="showDesktopProgressBar"
          :current-percentage="mobileProgressWidthPercentage"
          :progress-animation="progressAnimation"
          :progress-animation-delay="progressAnimationDelay"
        />
      </div>
      <div
        class="col-2 col-sm-3 col-md-4 d-flex align-items-center justify-content-end pe-2 pe-sm-3 pe-md-4"
      >
        <button
          v-if="isHelpDataAvailable"
          class="button-help bg-color-primary"
        >
          <font-awesome-icon
            icon="question-circle"
            size="2x"
            @click="functionHelpButton"
          />
        </button>
      </div>
    </header>

    <div
      v-if="getMobileView && !progressBarRelative"
      id="mobile-progress-bar"
      :class="
        progressBarRelative
          ? 'mobile-progress-bar-relative'
          : 'mobile-progress-bar-fixed'
      "
      class="mobile-progress-bar"
      aria-hidden="true"
    >
      <div
        :style="[
          { width: mobileProgressWidthPercentage },
          progressAnimation,
          progressAnimationDelay,
        ]"
        class="mobile-progress"
      />
      <button
        v-if="isHelpDataAvailable"
        class="btn mobile-help-button"
      >
        <font-awesome-icon
          icon="question-circle"
          style="font-size: 20px"
          @click="functionHelpButton"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppHeaderLogo from './AppHeaderLogo.vue';
import AppHeaderProgressBar from './AppHeaderProgressBar.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { useConsultationStore } from '../stores/useConsultationStore';
library.add(faQuestionCircle);

export default defineComponent({
  components: {
    AppHeaderLogo,
    AppHeaderProgressBar,
  },

  setup() {
    const analyticsStore = useAnalyticsStore();
    const consultationStore = useConsultationStore();
    return { analyticsStore, consultationStore };
  },

  data() {
    return {
      progressBarRelative: true,
      headerHeight: 60, // A somewhat sensible default
    };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
      'isLandscapeMode'
    ]),

    ...mapState(useConsultationStore, [
      'stageIndex',
      'stagesCount',
      'locale',
      'inChoiceTask',
      'getProjectTitle',
      'getHelpData',
    ]),


    landscapeMode() {
      return this.isLandscapeMode && this.inChoiceTask;
    },

    isHelpDataAvailable() {
      if (
        this.getHelpData &&
        this.getHelpData[this.locale] &&
        this.getHelpData[this.locale] !== ''
      ) {
        return this.getHelpData[this.locale];
      } else {
        return false;
      }
    },

    mobileProgressWidthPercentage() {
      if (!this.stagesCount) {
        return '100%';
      }

      const step = this.stageIndex + 1;

      return `${(step / this.stagesCount) * 100}%`;
    },

    showDesktopProgressBar() {
      if (this.getMobileView && !this.progressBarRelative) {
        return false;
      }

      return true;
    },

    progressAnimation() {
      return {
        transition: 'all 0.2s ease-out',
      };
    },

    progressAnimationDelay() {
      return {
        transitionDelay: '0.4s',
      };
    },
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  mounted() {
    const header = document.getElementById('header');

    if (header) {
      this.headerHeight = header.offsetHeight;
    }
  },

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    functionHelpButton() {
      this.analyticsStore.trackUserAction(['Help']);

      this.consultationStore.$patch({
        showHelp: true,
        infoMode: 'help',
      });
    },

    handleScroll(event) {
      if (document.body.scrollTop > this.headerHeight || document.documentElement.scrollTop > this.headerHeight) {
        this.progressBarRelative = false;
      } else {
        this.progressBarRelative = true;
      }
    },
  },
});
</script>

<style scoped>
.header-container {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  transition: width 0.1s ease;
}

header {
  height: 56px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  background-color: var(--primary-custom-header);
}

.bg-color-primary {
  background-color: var(--primary-custom-header);
}

.header-landscape,
.header-landscape .mobile-progress-bar,
.header-landscape .header-fixed {
  width: calc(100% - 44px);
  transition-delay: 0.3s;
}

.mobile-progress-bar-relative {
  position: relative;
}

.mobile-progress-bar-fixed {
  position: fixed;
  top: 0;
}

.mobile-progress-bar {
  display: block;
  background-color: white;
  width: 100%;
  z-index: 10;
}

.mobile-progress {
  background-color: #92c30c;
  height: 5px;
}

.mobile-progress-bar.hidden {
  display: none;
}

.header-relative {
  position: relative;
  width: 100%;
  z-index: 10;
}

.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.header-project-title {
  color: var(--text-color-custom-header);
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  max-width: 120px;
  user-select: none;
  line-height: 1;
}

.button-help {
  border-style: none;
  transition: transform var(--btn-hover-zoom-transition-time);
  color: white;
  z-index: 1;
}

.header-landscape .button-help {
  right: calc(0.5rem + 44px);
}

.button-help:hover {
  transform: scale(var(--btn-hover-zoom-scale));
}

.button-help:focus,
.button-help:focus-within,
.button-help svg:focus,
.button-help svg:focus-visible {
  outline: none;
  box-shadow: none;
}
</style>
