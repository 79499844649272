<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <SegmentWrapper />
  </transition>
</template>

<script>
import SegmentWrapper from '../components/SegmentWrapper.vue';
import { useConsultationStore } from '../stores/useConsultationStore';

export default {
  components: {
    SegmentWrapper,
  },

  setup() {
    const consultationStore = useConsultationStore();
    return { getDocumentTitle: consultationStore.getDocumentTitle };
  },

  mounted() {
    document.title = this.getDocumentTitle;
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 1.5s ease;
}

.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
