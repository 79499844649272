<template>
  <div class="info-button-wrapper">
    <font-awesome-layers
      class="info-button button-hover-animation"
      @click="showOptionDetails()"
    >
      <font-awesome-icon icon="square" />
      <font-awesome-icon
        :style="{ color: 'white' }"
        icon="info"
        transform="shrink-8"
      />
    </font-awesome-layers>
  </div>
</template>

<script lang="ts">
import { mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'showOptionDetailModal',
    ]),
  },

  methods: {
    showOptionDetails() {
      this.showOptionDetailModal = this.option;
    },
  },
});
</script>
