<template>
  <div class="research-dashboard-wrapper">
    <transition
      name="fade"
      tag="div"
      mode="out-in"
    >
      <component :is="currentComponent" />
    </transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import DashboardAttributes from './DashboardAttributes.vue';
import DashboardMuted from './DashboardMuted.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    DashboardMuted,
    DashboardAttributes,
  },

  computed: {
    ...mapState(useConsultationStore, [
      'skipChoiceTask'
    ]),

    currentComponent() {
      if (this.skipChoiceTask) {
        return 'dashboard-muted';
      }

      return 'dashboard-attributes';
    },
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.15s ease-in;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.research-dashboard-wrapper {
  position: relative;
  background-color: white;
  box-sizing: border-box;
  width: 340px;
  text-align: left;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
