<template>
  <div class="research-dashboard-desktop-wrapper">
    <ResearchDashboard
      class="research-dashboard-desktop ps-3 pe-3 ps-md-4 pe-md-4"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ResearchDashboard from './ResearchDashboard.vue';

export default defineComponent({
  components: {
    ResearchDashboard,
  },
});
</script>

<style scoped>
.research-dashboard-desktop-wrapper {
  box-shadow: -3px 3px 6px rgb(0 0 0 / 16%);
  min-height: 100vh;
  background: white;
  position: sticky;
  top: 0px;
}

.research-dashboard-desktop {
  position: sticky;
  top: 56px;
}
</style>
