<template>
  <div>
    <div class="slider-container">
      <div
        class="slider-overlay"
        :style="skipChoiceTask ? 'cursor: not-allowed;' : 'cursor: pointer;'"
        @click="functionClickSliderModal()"
      />
      <div
        v-if="isSymmetricalSlider"
        class="symmetrical-slider-wrapper"
      >
        <span
          style="width: 50%"
          class="scale-marker-down-preview scale-marker-left-preview"
        />
        <ChoiceTaskSliderInputEnabled
          v-if="!skipChoiceTask"
          :slider-id="option.id"
          :current-value="getCurrentValueSymmetrical"
          :min-value="minValue"
          :max-value="maxValue"
          :static-mode="true"
          :symmetrical="true"
        />
        <ChoiceTaskSliderInputDisabled v-else />
      </div>
      <div v-else>
        <ChoiceTaskSliderInputEnabled
          v-if="!skipChoiceTask"
          :slider-id="option.id"
          :current-value="getCurrentValue"
          :min-value="minValue"
          :max-value="maxValue"
          :static-mode="true"
        />
        <ChoiceTaskSliderInputDisabled v-else />
      </div>
    </div>

    <div
      v-if="showSliderModal"
      class="page-overlay"
      @click="functionClickSliderModal()"
    />

    <div
      v-if="showSliderModal"
      class="slider-modal-wrapper"
    >
      <div class="slider-modal-container">
        <div>
          <div
            class="container-fluid row justify-content-end ms-0 dialog-closing-button-container p-0"
          >
            <font-awesome-layers
              class="dialog-closing-button-wrapper"
              @click="closeSliderModal()"
            >
              <font-awesome-icon
                icon="square"
                class="closing-square-icon"
              />
              <font-awesome-icon
                icon="times"
                class="closing-cross-icon"
              />
            </font-awesome-layers>
          </div>
        </div>

        <ul class="slider-wrapper">
          <ChoiceTaskSliderModeCard
            v-if="isSliderMode"
            :option="option"
            :show-info="false"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskSliderModeCard from './ChoiceTaskSliderModeCard.vue';
import ChoiceTaskSliderInputEnabled from './ChoiceTaskSliderInputEnabled.vue';
import ChoiceTaskSliderInputDisabled from './ChoiceTaskSliderInputDisabled.vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    ChoiceTaskSliderModeCard,
    ChoiceTaskSliderInputEnabled,
    ChoiceTaskSliderInputDisabled,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: false,
      minValue: 0,
      labelScaleMin: null,
      labelScaleMax: null,
      currentValue: 0,
      showMinMaxLabel: null,
      showSliderModal: false,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'stageId',
      'isSliderMode',
      'isSymmetricalSlider',
      'getAnswerData',
      'skipChoiceTask',
    ]),

    answerData() {
      return this.getAnswerData[this.stageId].answers;
    },

    maxValue() {
      return this.isSymmetricalSlider
        ? this.option.step_size * 2
        : this.option.step_size;
    },

    getCurrentValue() {
      const optionObject = this.answerData[this.option.id];
      let currentValue = 0;

      if (optionObject && optionObject.quantity) {
        currentValue = optionObject.quantity * this.maxValue;
      }

      if (this.$refs.previewSlider) {
        this.$refs.previewSlider.style.setProperty('--value', currentValue);
      }

      return currentValue;
    },

    getCurrentValueSymmetrical() {
      const optionObject = this.answerData[this.option.id];
      let currentValue = 0;

      //  conversion back to the symmetrical range
      if (optionObject && optionObject.quantity) {
        const halfValue = this.maxValue / 2;
        let ratio = optionObject.quantity;
        let newValue = null;
        if (Math.sign(ratio) === -1) {
          ratio = ratio * -1;
          newValue = ratio * halfValue;
          currentValue = halfValue - newValue;
        } else if (Math.sign(ratio) === 1) {
          newValue = ratio * halfValue;
          currentValue = newValue + halfValue;
        }
      } else {
        currentValue = this.maxValue / 2;
      }

      if (this.isSymmetricalSlider) {
        return this.option.step_size * 2;
      }

      if (this.$refs.previewSlider) {
        this.$refs.previewSlider.style.setProperty('--value', currentValue);
      }

      return currentValue;
    },
  },

  watch: {
    skipChoiceTask(bool) {
      if (!bool) {
        this.updateSlider();
      }
    },
  },

  mounted() {
    this.updateSlider();
  },

  methods: {
    updateSlider() {
      for (const e of document.querySelectorAll(
        'input[type="range"].slider-progress'
      )) {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min == '' ? '0' : e.min);
        e.style.setProperty('--max', e.max == '' ? '100' : e.max);
        e.addEventListener('input', () => e.style.setProperty('--value', e.value));
      }
    },

    functionClickSliderModal() {
      if (!this.skipChoiceTask) {
        this.showSliderModal = !this.showSliderModal;
      }
      return;
    },

    closeSliderModal() {
      this.showSliderModal = false;
    },
  },
});
</script>

<style scoped>
li {
  list-style: none;
  border: none;
  box-shadow: none;
}

.page-overlay {
  z-index: 2000;
  background-color: #dcdcdc70;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-modal-container {
  position: fixed;
  top: 10%;
  left: 50%;
  margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  z-index: 3001;
  background-color: white;
  padding: 8px;
  border: 1px solid #e9eff4;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  width: 100%;
  max-width: 800px;
}

.slider-wrapper {
  padding: 0;
}

.slider-modal-header {
  text-align: right;
}

.slider-scale {
  position: relative;
  user-select: none;
  background-color: transparent;
}

.slider-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.scale-marker-down-preview {
  position: absolute;
  top: 32px;
  left: 1px;
  height: 7px;
  pointer-events: none;
  transition: all 3sec;
}

.scale-marker-left-preview {
  border-right: 2px solid #90969b;
  opacity: 0.5;
}

.slider-container {
  background-color: white;
  padding: 8px;
  border: 1px solid #e9eff4;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  position: relative;
}

.slider-container input[type='range']::-webkit-slider-thumb {
  z-index: -1;
  cursor: pointer !important;
}

.slider-container input[type='range']::-moz-range-thumb {
  cursor: pointer !important;
}

.slider-container input[type='range']::-ms-thumb {
  cursor: pointer !important;
}
</style>
